import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
class AlertPermissionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: props.modal || false,
        };
    }
    render() {
        return (
            <Modal isOpen={this.props.modal}
                size='sm'
                toggle={this.props.Modal}
                className={"text-center"}
            >
                <ModalBody
                    style={{
                        backgroundColor: 'orange',
                        borderRadius: '5px',
                        border: "2px solid DarkOrange",
                        color: 'white'
                    }}
                >
                    <p>Sem Permissão!</p>
                    <p> Não faz parte do plano contratado.</p>
                </ModalBody>
            </Modal>

        );
    }
}
export default AlertPermissionModal;