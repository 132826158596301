import axios from "axios";

export default class GenericEntity {

    url = '';
    id = null;

    constructor(obj) {
        Object.assign(this, obj);
    }

    verifyIdOrThrow() {
        if (!(this.id != null)) throw new Error(`Element without ID value`);
    }
    async load(params) {
        let _params = {};
        if (params)
            Object.assign(_params, params);

        const url = _params?.url != null ? _params?.url : `${this.url}/${this.id}`;

        _params.url = undefined;

        return new Promise(async (res, rej) => {
            if (this.id) {
                let { data } = await axios.get(url, {
                    params: _params
                });
                Object.assign(this, data);
            }
            else { throw new Error(`Element without ID value`); }
            res(this);
        });
    }
    async update(params) {
        let _params = {};
        if (params)
            Object.assign(_params, params);

        const withRootUrl = _params?.withRootUrl;
        
        const url = withRootUrl ? this.url : `${this.url}/${this.id}`;

        _params.withRootUrl = undefined;
        this.verifyIdOrThrow();
        return new Promise(async (res, rej) => {
            axios.put(url, this, {
                params: _params
            }).then(response => {
                Object.assign(this, response.data);
                res(this);
            });
        });
    }
    async save(params) {
        let _params = {};
        if (params)
            Object.assign(_params, params);
        return new Promise(async (res, rej) => {
            if (!this.id)
                axios.post(`${this.url}`, this, {
                    params: _params
                }).then(response => {
                    let { data } = response;
                    Object.assign(this, data);
                    res(this);
                });
            else
                res(await this.update(params));
        });
    }
    async delete(options) {
        const url = options?.url ?? this.url;

        this.verifyIdOrThrow();
        return new Promise(async (res, rej) => {
            let { data } = await axios.delete(`${url}/${this.id}`);
            res(data);
        });
    }

    async find({ params, url }) {
        let _params = {};
        if (params)
            Object.assign(_params, params);
        return new Promise(async (res, rej) => {
            let { data } = await axios.get(`${url ?? this.url}`, {
                params: _params
            });
            if (data)
                Object.assign(this, data);
            res(this);
        });
    }

    absorve(obj) {
        Object.assign(this, obj);
    }
}