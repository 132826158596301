function valuePermission(params) {
    let plan = JSON.parse(localStorage.getItem('PlanPermission'));
    //se array zerado libera tudo
    if(!plan)
      return true;
   let permission= plan.permissions.includes(params)??false
    if (permission)
      return true;
    else
      return false;
}
export default valuePermission;