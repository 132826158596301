
export const EnumPermission = {
    WHATSAPP: 0,
    SMS: 1,
    NFSE: 2,
    PROFESSIONALS: 3,
    PACKAGEREGISTRATION: 4,
    REGISTRATIONSUBSCRIPTIONPLANS: 5,
    LINKSPAGE: 6,
    PAYMENTGATEWAY: 7,
    BOTULINUMTOXIN: 8,
    OROFACIALHARMONIZATION: 9,
    ATTACHED: 10,
    ATTESTATION: 11,
    ANAMNESES: 12,
    STOCK: 13,
    SATISFACTIONSURVEY: 14,
    HOFPROCEDURES: 15
};