import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import "./Vendor";
import './styles/bootstrap.scss';
import './styles/app.scss'
import { history } from './history'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-toastify/dist/ReactToastify.css"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import jwtDecode from "jwt-decode";

class App extends Component {

  onServiceWorkerUpdate = registration => {
    if (registration && registration.waiting)
      this.refreshAction(registration)
  }

  updateServiceWorker = (registration) => {
    const waitingWorker = registration && registration.waiting;
    if (waitingWorker) {
      waitingWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          console.log('reloaded update SW');
          this.forceUpdate();
          window.location.href = process.env.HOST;
          // window.location.reload()
        }
      });
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }

  componentDidMount = async () => {

    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'homolog') {
      serviceWorkerRegistration.register({
        onUpdate: this.onServiceWorkerUpdate
      });
    };
  }

  refreshAction = (registration) => {
    localStorage.clear();
    sessionStorage.clear();
    this.updateServiceWorker(registration)
  };

  render() {
    // axios.defaults.timeout = 30000
    let isOpenCheck = false;
    axios.interceptors.response.use((response) => {
      // Do something with response data
      return response;

    }, (error) => {
      // Do something with response error
      // You can even test for a response code 
      // and try a new request before rejecting the promise
      if (error.response) {
        const requestConfig = error.config;
      }
      if (error.response && error.response.status == 401) {
        {
          toast.warn("Acesso expirado. Favor fazer o login novamente!", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            onClose: () => isOpenCheck = false
          });
          history.push('/');
        }
      }
      else if (isOpenCheck == false) {
        toast.warn("Falha na Conexão. Favor entrar em contato com o suporte!", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          onClose: () => isOpenCheck = false
        });
      }
      isOpenCheck = true
      return Promise.reject(error);
    });

    axios.interceptors.request.use(async (config) => {
      let app_token = JSON.parse(localStorage.getItem('app-token'));
      if (app_token != null) {

        if (jwtDecode(app_token.token).exp < Date.now() / 1000) {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/login')
        }
      }
      let items = JSON.parse(localStorage.getItem('ClinicData'))
      let id = items != null ? parseInt(items.id) : 0

      if (config.url.endsWith('api/clinic')) {
        //passa o id do usuário para alterações em dados da clínica
        items = JSON.parse(localStorage.getItem('app-token')) ?? ''
        id = parseInt(items.id)
      }
      //acesso como provider 
      let tenantid = config.params != undefined ? config.params['tenantid'] ?? '' : '';

      config.headers = {
        'Authorization': app_token != null ? `Bearer ${app_token.token}` : '',
        'tenantid': tenantid != '' ? tenantid : id,
      }

      return config;
    }, (error) => {
      // I cand handle a request with errors here
      return Promise.reject(error);
    });

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */

    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
      <Router basename={basename} history={history}>
        <Routes />
      </Router>
    );
  }
}
export default App;

